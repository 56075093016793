import PropTypes from "prop-types"
import React, { useState } from "react"
import { Grid, Col, Row } from 'react-styled-flexboxgrid'

const Header = () => {

  const [punch, setPunch] = useState("14");
  const [draft, setDraft] = useState("This is Megalove");

  return (
    <header className="site-header">
      <div className="site-header__sample">
        <div className="site-header__text-solid" style={{ fontSize: `${punch}vw` }}>{draft}</div>
        <div className="site-header__text-shadow" style={{ fontSize: `${punch}vw` }}>{draft}</div>
      </div>
      <div className="site-header__controls">
        <Grid>
          <Row middle="xs" between="xs">
            <Col md={8} sm={12} xs={12}>
              <div className="controls-input">
                <div className="controls-label">
                  Edit text (letters only):
                </div>
                <input className="input" type="text" placeholder="This is Megalove"
                  onChange={function (e) {
                    // setDraft(event.target.value)
                    const re = /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g;
                    if (e.target.value === '' || re.test(e.target.value)) {
                      //  this.setState({value: e.target.value})
                      setDraft(e.target.value)
                    };
                  }
                  } />
              </div>
            </Col>
            <Col md={4} sm={12} xs={12}>
              <div className="controls-slider">
                <div className="controls-label">
                  Size:
                </div>
                <input
                  className="range-slider"
                  type="range"
                  value={punch}
                  min={8}
                  max={28}
                  onChange={function (event) {
                    setPunch(event.target.valueAsNumber)
                  }}
                />
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
